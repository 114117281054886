<template>
  <app-layout>
    <transition name="component-fade" mode="out-in">
      <div v-if="showSupplierForm" class="flex flex-col items-center py-4" key="form">
        <div class="w-full lg:w-1/2">
          <div class="flex flex-row items-center inline py-4">
            <sa-icon-button iconClass="bx bx-left-arrow-alt" @click="closeSupplierForm" with-bg />
            <span class="mx-2 text-lg font-bold">Proveedor</span>
          </div>
          <content-card>
            <supplier-form 
              :supplier="supplierToUpdate" 
              :is-new="isNewSupplier" 
              @onSaved="savedSupplier" 
              @onCancel="closeSupplierForm" 
            />
          </content-card>
        </div>
      </div>
      <div v-if="!showSupplierForm" class="flex flex-col items-center py-4" key="list">
        <div class="w-full px-12">
          <div class="flex flex-row items-center py-4">
            <h3 class="text-lg font-bold">Proveedores</h3>
            <div class="ml-auto">
              <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="createSupplier">
                Crear Proveedor
              </sa-button>
            </div>
          </div>
          <content-card>
            <suppliers-list @onEdit="updateSupplier" />
          </content-card>
        </div>
      </div>
    </transition>
  </app-layout>
</template>

<script>
import SupplierForm from './SupplierForm.vue';
import SuppliersList from './SuppliersList.vue';
import ContentCard from '@/components/molecules/ContentCard.vue';
import AppLayout from '@/components/layouts/AppLayout.vue';

export default {
  name: 'SuppliersIndex',
  components: {
    SupplierForm,
    SuppliersList,
    ContentCard,
    AppLayout
  },
  data() {
    return {
      showSupplierForm: false,
      isNewSupplier: false,
      supplierToUpdate: {
        id: null,
        company: '',
        webpage: '',
        contactFullName: '',
        contactEmail: '',
        phone: '',
        contactDescription: '',
      },
    };
  },
  methods: {
    savedSupplier() {
      this.showSupplierForm = false;
      this.$toastr.s(this.isNewSupplier ? 'Proveedor creado con éxito' : 'Proveedor actualizado con éxito');
    },
    updateSupplier(supplier) {
      this.supplierToUpdate = supplier;
      this.isNewSupplier = false;
      this.showSupplierForm = true;
    },
    createSupplier() {
      if (this.$store.state.business.id) {
        this.isNewSupplier = true;
        this.showSupplierForm = true;
      } else {
        this.$toastr.i("Debes estar en una sucursal para crear proveedores");
      }
    },
    closeSupplierForm() {
      this.resetForm();
    },
    resetForm() {
      this.showSupplierForm = false;
      this.isNewSupplier = false;
      this.supplierToUpdate = {
        id: null,
        company: '',
        webpage: '',
        contactFullName: '',
        contactEmail: '',
        phone: '',
        contactDescription: '',
      };
    }
  },
};
</script>
